/* eslint-disable max-len */
import React from 'react';

interface WHEELS26Props {
    /**
     * A string representing the CSS class to be applied to the WHEELS26Icon element to override its default styling.
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     */
    color1?: string;
    /**
     * A number or string representing the height of the WHEELS26Icon element.
     */
    height?: number | string;
    /**
     * A number representing the stroke width of the WHEELS26Icon element.
     */
    strokeWidth?: number;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     */
    testId?: string;
    /**
     * A number or string representing the height of the WHEELS26Icon element.
     */
    width?: number | string;
}

/**
 * The `WHEELS26` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `WHEELS26Props` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the WHEELS26Icon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the WHEELS26Icon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the WHEELS26Icon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const WHEELS26Component = <WHEELS26 className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const WHEELS26 = React.forwardRef<SVGSVGElement, WHEELS26Props>((props, ref) => {
    const {className, color1, height, strokeWidth, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 38.27 38.27"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <defs>
                <clipPath id="a">
                    <path d="M3.19 19.13c0 8.67 6.92 15.72 15.53 15.94h.41A15.95 15.95 0 1 0 3.19 19.13" fill="none" strokeWidth="0" />
                </clipPath>
            </defs>
            <path d="M19.13 36.67c9.67 0 17.54-7.87 17.54-17.54S28.8 1.59 19.13 1.59 1.59 9.46 1.59 19.13s7.87 17.54 17.54 17.54Z" fill="none" stroke={color1} strokeWidth={strokeWidth} />
            <g clipPath="url(#a)">
                <path d="M35.08 19.13a15.95 15.95 0 1 1-31.87-.01 15.95 15.95 0 0 1 31.86.02" />
                <path d="M17.07 19.92a5.5 5.5 0 0 1-.48 2.03c-.25.52-.53.82-.82.89l-7.13 1.59-.05.13a.27.27 0 0 0-.03.12l.05 1.37 8.6-1.92.13 3.25L5.6 30l-.22-5.56c0-.28.03-.58.11-.88.08-.3.17-.59.29-.87.11-.28.24-.52.38-.71.14-.19.27-.32.39-.36L13.81 20c.05-.12.08-.21.07-.28l-.02-.42c-.03-.3-.07-.46-.1-.5l-8.01 1.79-.13-3.32 9.61-2.15c.15-.03.32.02.52.16s.38.34.56.58a4 4 0 0 1 .44.85c.12.33.19.65.2.97l.1 2.24Zm1.18-3.05a3.74 3.74 0 0 1 .73-2.26c.13-.16.26-.28.37-.35h-.02l.07-.06.1-.04 10.45-2.34.13 3.25-8.6 1.92c-.05.05-.06.08-.03.07v.03l.05 1.17 7.99-1.79c.16-.04.33.02.5.17.17.15.33.34.47.57.14.23.26.48.36.74.1.26.15.49.17.68l.13 3.25c0 .22-.03.47-.11.77-.08.29-.19.57-.32.84s-.26.5-.38.71c-.13.2-.24.32-.32.33l-9.91 2.22c-.14.03-.3 0-.47-.07a1.67 1.67 0 0 1-.49-.38c-.15-.17-.28-.4-.39-.67a2.9 2.9 0 0 1-.17-.93l-.31-7.84ZM27.72 20l-6.11 1.37.05.94c0 .24.06.45.17.62.11.17.23.24.37.21l5.63-1.26c.06-.01.12-.13.2-.34a1.72 1.72 0 0 0-.03-1.26c-.08-.21-.17-.31-.28-.28" fill={color1} />
                <path d="m30.53 12.81-1.93.43-.12-4.28 1.92-.43.13 4.28zm2.39-.53-1.93.43-.13-4.28L32.79 8l.13 4.28z" fill={color1} />
            </g>
        </svg>
    );
});

WHEELS26.displayName = 'WHEELS26';
WHEELS26.defaultProps = {
    className: '',
    color1: '#fff',
    height: 38.27,
    strokeWidth: 3.19,
    testId: 'WHEELS26',
    width: 38.27
};