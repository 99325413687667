import type {Variants} from 'framer-motion';

export const PopoverAnimation: Variants = {
    hover: {
        opacity: 1,
        y: 'calc(100% + 12px)'
    },
    initial: {
        opacity: 0,
        y: 'calc(0% + 0px)'
    }
};

export const PopoverWrapperAnimation: Variants = {
    hover: {},
    initial: {}
};