/* eslint-disable max-len */
import React from 'react';

interface HANDLEBARStabProps {
    /**
     * A string representing the CSS class to be applied to the HANDLEBARStabIcon element to override its default styling.
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     */
    color1?: string;
    /**
     * A string representing the color to be applied.
     */
    color2?: string;
    /**
     * A number or string representing the height of the HANDLEBARStabIcon element.
     */
    height?: number | string;
    /**
     * A number representing the stroke width of the HANDLEBARStabIcon element.
     */
    strokeWidth?: number;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     */
    testId?: string;
    /**
     * A number or string representing the height of the HANDLEBARStabIcon element.
     */
    width?: number | string;
}

/**
 * The `HANDLEBARStab` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `HANDLEBARStabProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the HANDLEBARStabIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.color2    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the HANDLEBARStabIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the HANDLEBARStabIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const HANDLEBARStabComponent = <HANDLEBARStab className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const HANDLEBARStab = React.forwardRef<SVGSVGElement, HANDLEBARStabProps>((props, ref) => {
    const {className, color1, color2, height, strokeWidth, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 42.34 38.27"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <defs>
                <clipPath id="a">
                    <path d="M7.26 19.13a15.95 15.95 0 1 0 31.9 0 15.95 15.95 0 0 0-31.9 0" fill="none" strokeWidth="0" />
                </clipPath>
                <clipPath id="b">
                    <path d="M8.27 13.53a15.95 15.95 0 0 0 26.2 16.89l.01-.01a15.9 15.9 0 0 0 1.88-20.25 15.95 15.95 0 0 0-28.1 3.39" fill="none" strokeWidth="0" />
                </clipPath>
            </defs>
            <path d="M23.2 36.67c9.67 0 17.54-7.87 17.54-17.54S32.87 1.59 23.2 1.59 5.66 9.46 5.66 19.13s7.87 17.54 17.54 17.54" fill={color1} />
            <g clipPath="url(#a)">
                <path d="M7.26 19.13a15.94 15.94 0 1 1 31.86 0 15.94 15.94 0 0 1-31.85 0" />
            </g>
            <g clipPath="url(#b)">
                <path d="m0 27.26 29-16.25 3.03 5.4-29 16.26z" fill={color1} />
                <path d="m21.97 11.49 4.98 8.55L29.77 30l-8.52 4.93-10.94-6.55 1.06-.93 2.28-1.44.38-.62-7.07-11.82 1.46-1.45.96-.33.01-1.87.64-1.25 2.03-.08 1.43 1.52.72-1.4h2.19l2.74 4.49" fill={color1} />
                <path d="m10.37 12.65 5.41 9.23.64-.4-5.18-8.94-.37-.89-1.09-1.63m2.16-1.15 1.04 1.8.61 1.06 5 8.62.72-.5-5.44-9.4m2.9.16.79 1.35.48.83 3.99 6.88.69-.42-5.21-9.04m2.58-1.01 6.34 10.99 2.61 9.05-8.2 4.75-7.66-4.31-2.14-1.2 2.84-1.87 1.69.38-6.42-11.17m9.92-6.46-.87 1.14-1.41-2.44-2.95-.37-.65 1.4-.77-1.32-2.37.61-1.78 1.76.82 1.41-1.47.13-1.15 2.13 7.28 11.93-.43-.02-3.5 2.46.47.76 10.64 6.41 9.63-5.53-2.93-10.16-6.07-10.49-2.48.15Z" />
                <path d="M33.64 15.25a2.6 2.6 0 0 1-1.02 3.52" fill="none" stroke={color1} strokeMiterlimit="10" strokeWidth=".46" />
                <path d="M35.93 12.33a7.59 7.59 0 0 1-3 10.3" fill="none" stroke={color1} strokeMiterlimit="10" strokeWidth="1.02" />
                <path d="M26.8 9.41c1.18-.81 2.8-.5 3.6.68" fill="none" stroke={color1} strokeMiterlimit="10" strokeWidth=".46" />
                <path d="M23.68 7.41a7.6 7.6 0 0 1 10.54 1.98" fill="none" stroke={color1} strokeMiterlimit="10" strokeWidth="1.02" />
                <path d="m27.28 11.33.71 1.37-2.16 1.25 2 3.57 2.43-1.4.81 1.31" fill="none" stroke={color2} strokeMiterlimit="10" strokeWidth=".54" />
            </g>
            <path d="M23.2 36.67c9.67 0 17.54-7.87 17.54-17.54S32.87 1.59 23.2 1.59 5.66 9.46 5.66 19.13s7.87 17.54 17.54 17.54Z" fill="none" stroke={color1} strokeWidth={strokeWidth} />
        </svg>
    );
});

HANDLEBARStab.displayName = 'HANDLEBARStab';
HANDLEBARStab.defaultProps = {
    className: '',
    color1: '#fff',
    color2: '#000',
    height: 38.27,
    strokeWidth: 3.19,
    testId: 'HANDLEBARStab',
    width: 42.34
};