/* eslint-disable max-len */
import React from 'react';

interface TAPHeadtubeProps {
    /**
     * A string representing the CSS class to be applied to the TAPHeadtubeIcon element to override its default styling.
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     */
    color1?: string;
    /**
     * A number or string representing the height of the TAPHeadtubeIcon element.
     */
    height?: number | string;
    /**
     * A number representing the stroke width of the TAPHeadtubeIcon element.
     */
    strokeWidth?: number;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     */
    testId?: string;
    /**
     * A number or string representing the height of the TAPHeadtubeIcon element.
     */
    width?: number | string;
}

/**
 * The `TAPHeadtube` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `TAPHeadtubeProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the TAPHeadtubeIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the TAPHeadtubeIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the TAPHeadtubeIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const TAPHeadtubeComponent = <TAPHeadtube className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const TAPHeadtube = React.forwardRef<SVGSVGElement, TAPHeadtubeProps>((props, ref) => {
    const {className, color1, height, strokeWidth, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 38.27 38.27"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <defs>
                <clipPath id="a">
                    <path d="M12.54 4.61a15.96 15.96 0 0 0 6.59 30.47c4.4 0 8.39-1.78 11.27-4.67l.01-.01A15.94 15.94 0 0 0 12.54 4.62" fill="none" strokeWidth="0" />
                </clipPath>
            </defs>
            <g clipPath="url(#a)">
                <path d="M35.08 19.13a15.94 15.94 0 1 1-31.86 0 15.94 15.94 0 0 1 31.85 0" />
                <path d="M19.13 35.92c-9.26 0-16.79-7.53-16.79-16.79S9.88 2.35 19.13 2.35s16.79 7.53 16.79 16.79-7.53 16.79-16.79 16.79" />
                <path d="M19.13 3.19a15.95 15.95 0 1 0 15.94 15.95c0-8.79-7.14-15.95-15.94-15.95" />
                <path d="M12.61 6.41v2.88L8.84 25.35v4.59h2.4v-4.58l3.49-15.97V6.41h-2.12zm13.05 0v2.88l3.77 16.06v4.59h-2.41v-4.58L23.54 9.39V6.41h2.12z" fill={color1} />
                <path d="M12.61 6.41h13.05m3.77 23.53H8.84" fill="none" stroke={color1} strokeMiterlimit="10" strokeWidth=".38" />
            </g>
            <path d="M19.13 36.67c9.67 0 17.54-7.87 17.54-17.54S28.8 1.59 19.13 1.59 1.59 9.46 1.59 19.14s7.87 17.54 17.54 17.54Z" fill="none" stroke={color1} strokeWidth={strokeWidth} />
        </svg>
    );
});

TAPHeadtube.displayName = 'TAPHeadtube';
TAPHeadtube.defaultProps = {
    className: '',
    color1: '#fff',
    height: 38.27,
    strokeWidth: 3.19,
    testId: 'TAPHeadtube',
    width: 38.27
};