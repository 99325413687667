/* eslint-disable max-len */
import React from 'react';

interface CLightProps {
    /**
     * A string representing the CSS class to be applied to the CLightIcon element to override its default styling.
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     */
    color1?: string;
    /**
     * A number or string representing the height of the CLightIcon element.
     */
    height?: number | string;
    /**
     * A number representing the stroke width of the CLightIcon element.
     */
    strokeWidth?: number;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     */
    testId?: string;
    /**
     * A number or string representing the height of the CLightIcon element.
     */
    width?: number | string;
}

/**
 * The `CLight` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `CLightProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the CLightIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the CLightIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the CLightIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const CLightComponent = <CLight className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const CLight = React.forwardRef<SVGSVGElement, CLightProps>((props, ref) => {
    const {className, color1, height, strokeWidth, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 38.27 38.27"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path d="M19.01 35.83c-9.2 0-16.69-7.49-16.69-16.69S9.8 2.44 19.01 2.44 35.7 9.93 35.7 19.13s-7.49 16.69-16.69 16.69" />
            <path d="M16.18 30.58h6.06l3.47-16.24-3.29-5.7h-6.43l-3.29 5.7 3.47 16.24Zm7.34 1.59h-8.63l-3.87-18.09 4.05-7.02h8.26l4.06 7.02-3.87 18.09Z" fill={color1} />
            <path d="M18.03 11.36h2.36v1.83h-2.36zm0 3.96h2.36v1.83h-2.36zm0 3.96h2.36v1.83h-2.36zm0 3.96h2.36v1.83h-2.36zm0 3.96h2.36v1.83h-2.36z" fill={color1} />
            <path d="M19.13 36.67c9.67 0 17.54-7.87 17.54-17.54S28.8 1.59 19.13 1.59 1.59 9.46 1.59 19.14s7.87 17.54 17.54 17.54Z" fill="none" stroke={color1} strokeWidth={strokeWidth} />
        </svg>
    );
});

CLight.displayName = 'CLight';
CLight.defaultProps = {
    className: '',
    color1: '#fff',
    height: 38.27,
    strokeWidth: 3.19,
    testId: 'CLight',
    width: 38.27
};