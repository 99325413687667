import type {ReactNode} from 'react';

import {spacing} from '@nfq/react-grid';
import {m as motion} from 'framer-motion';
import styled from 'styled-components';

import {P} from '../layout/Text';

import {PopoverAnimation, PopoverWrapperAnimation} from 'UI/animations/shared';

import type {WithChildren} from 'types/global';

interface ComponentProps {
    /**
     * The content to be displayed in the popup when hovered. This can be any ReactNode. If undefined, no popup is displayed.
     */
    content: ReactNode | undefined;
    /**
     * The identifier for this component to be used in testing. It's advisable to make this a unique value.
     */
    testId?: string;
}

/**
 * The `HoverInfoPopup` component is a wrapper that provides a popup on hover functionality.
 * The popup displays any React node content passed as the `content` prop.
 * If no `content` prop is passed, the component only renders its children, essentially acting as a pass-through component.
 * The component's `testId` prop is used for identification during testing, following best practices for accessible and testable components.
 *
 * @param props          The component props.
 * @param props.content  The content to be displayed in the popup. This can be any ReactNode. If undefined, no popup is displayed.
 * @param props.testId   The identifier for this component to be used in testing.
 * @param props.children The children components that should be rendered as part of the `HoverInfoPopup` component.
 * @returns The HoverInfoPopup component that shows the provided content in a popup when hovered.
 *
 * @example
 * ```tsx
 * <HoverInfoPopup content={<p>Hover info</p>} testId="hover-info">
 *     <button>Hover over me</button>
 * </HoverInfoPopup>
 * ```
 */
const HoverInfoPopup = ({children, content, testId = 'HoverInfoPopup'}: WithChildren<ComponentProps>) => (content ? (
    <Wrapper data-cy={testId} initial="initial" variants={PopoverWrapperAnimation} whileHover="hover">
        <PopUp variants={PopoverAnimation}>
            <P $align="center" $size="small" as="div">{content}</P>
        </PopUp>
        {children}
    </Wrapper>
) : children);

HoverInfoPopup.displayName = 'HoverInfoPopup';

export {HoverInfoPopup};

const Wrapper = styled(motion.div)`
    cursor: pointer;
    display: inline;
    line-height: 0;
    position: relative;
`;

const PopUp = styled(motion.div)`
    background-color: ${({theme}) => theme.colors.primaryActionColor};
    border-radius: 4px;
    bottom: 0;
    box-shadow: ${({theme}) => theme.boxShadows.popOverShadow};
    color: ${({theme}) => theme.colors.primaryActionFontColor};
    left: 50%;
    max-width: 20rem;
    opacity: 0;
    padding: ${spacing(2)} ${spacing(3)};
    pointer-events: none;
    position: absolute;
    transform: translateY(0%);
    translate: -50%;
    width: max-content;
    z-index: 10;
`;