/* eslint-disable max-len */
import React from 'react';

interface SUSSeatpostProps {
    /**
     * A string representing the CSS class to be applied to the SUSSeatpostIcon element to override its default styling.
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     */
    color1?: string;
    /**
     * A number or string representing the height of the SUSSeatpostIcon element.
     */
    height?: number | string;
    /**
     * A number representing the stroke width of the SUSSeatpostIcon element.
     */
    strokeWidth?: number;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     */
    testId?: string;
    /**
     * A number or string representing the height of the SUSSeatpostIcon element.
     */
    width?: number | string;
}

/**
 * The `SUSSeatpost` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `SUSSeatpostProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the SUSSeatpostIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the SUSSeatpostIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the SUSSeatpostIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const SUSSeatpostComponent = <SUSSeatpost className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const SUSSeatpost = React.forwardRef<SVGSVGElement, SUSSeatpostProps>((props, ref) => {
    const {className, color1, height, strokeWidth, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 38.27 38.48"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <defs>
                <clipPath id="a">
                    <path d="M7.87 7.85a15.94 15.94 0 0 0 22.55 22.54A15.93 15.93 0 0 0 19.14 3.18c-4.4 0-8.38 1.78-11.27 4.66" fill="none" strokeWidth="0" />
                </clipPath>
            </defs>
            <path d="M19.13 36.67c9.67 0 17.54-7.87 17.54-17.54S28.8 1.59 19.13 1.59 1.59 9.46 1.59 19.13s7.87 17.54 17.54 17.54" fill={color1} />
            <g clipPath="url(#a)">
                <path d="M35.08 19.13a15.94 15.94 0 1 1-31.86 0 15.94 15.94 0 0 1 31.85 0" />
                <path d="m15.6 15.84 6.35 2.14-5.9 2.84 6.35 2.13-5.89 3.55 6.28 1.41" fill="none" stroke={color1} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.12" />
                <path d="m15.4 28.57 7.61-.69.9 9.9-7.62.69zM3.32 10.93c.56.04 21.76.18 22.67.19.91 0 8.01-.15 9.62.07 1.61.22 2.28 1.26 2.41 2.03.14.77-.13 1.86-.85 1.89-1.25.05-4.3-.8-6.14-.98-1.84-.18-8.76-.12-11.71 1.08-2.95 1.21-8.85 3.55-10.9 2.94a34.91 34.91 0 0 1-5.9-2.67c-.51-.33-1.62-4.72.8-4.55" fill={color1} />
            </g>
            <path d="M19.13 36.67c9.67 0 17.54-7.87 17.54-17.54S28.8 1.59 19.13 1.59 1.59 9.46 1.59 19.13s7.87 17.54 17.54 17.54Z" fill="none" stroke={color1} strokeWidth={strokeWidth} />
        </svg>
    );
});

SUSSeatpost.displayName = 'SUSSeatpost';
SUSSeatpost.defaultProps = {
    className: '',
    color1: '#fff',
    height: 38.48,
    strokeWidth: 3.19,
    testId: 'SUSSeatpost',
    width: 38.27
};