/* eslint-disable max-len */
import React from 'react';

interface WHEELS650bProps {
    /**
     * A string representing the CSS class to be applied to the WHEELS650bIcon element to override its default styling.
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     */
    color1?: string;
    /**
     * A string representing the color to be applied.
     */
    color2?: string;
    /**
     * A number or string representing the height of the WHEELS650bIcon element.
     */
    height?: number | string;
    /**
     * A number representing the stroke width of the WHEELS650bIcon element.
     */
    strokeWidth?: number;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     */
    testId?: string;
    /**
     * A number or string representing the height of the WHEELS650bIcon element.
     */
    width?: number | string;
}

/**
 * The `WHEELS650b` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `WHEELS650bProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the WHEELS650bIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.color2    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the WHEELS650bIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the WHEELS650bIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const WHEELS650bComponent = <WHEELS650b className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const WHEELS650b = React.forwardRef<SVGSVGElement, WHEELS650bProps>((props, ref) => {
    const {className, color1, color2, height, strokeWidth, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 38.27 38.27"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <defs>
                <clipPath id="a">
                    <path d="M1.42 2.76h35.43v32.76H1.42z" fill="none" strokeWidth="0" />
                </clipPath>
            </defs>
            <path d="M19.13 36.67c9.67 0 17.54-7.87 17.54-17.54S28.8 1.59 19.13 1.59 1.59 9.46 1.59 19.13s7.87 17.54 17.54 17.54Z" fill="none" stroke={color1} strokeWidth={strokeWidth} />
            <g clipPath="url(#a)">
                <path d="M34.54 19.13a16.38 16.38 0 1 1-32.75 0 16.38 16.38 0 0 1 32.75 0" />
                <path d="M18.16 2.75c-9.03 0-16.38 7.35-16.38 16.38s7.35 16.38 16.38 16.38 16.38-7.35 16.38-16.38S27.19 2.75 18.16 2.75" />
                <path d="M2.59 19.22a3.44 3.44 0 0 1 .54-1.92c.1-.15.2-.26.29-.33l.03-.05.11-.04 8.46-1.91.15 2.85-6.91 1.56-.03.06.05.98 6.45-1.45c.11-.03.24.02.39.15.14.13.27.29.4.49.12.2.22.41.31.64.09.23.14.42.15.59l.14 2.82c0 .17-.02.38-.09.63-.07.25-.15.5-.24.73-.1.24-.19.44-.3.6-.1.16-.19.25-.26.27L4.22 27.7c-.13.03-.26.01-.4-.05s-.27-.17-.39-.32a2.18 2.18 0 0 1-.31-.57c-.08-.23-.14-.5-.16-.81l-.35-6.73Zm7.71 2.81-4.9 1.1.05.78c0 .18.05.36.13.52.08.16.16.23.26.21l4.56-1.03c.08-.02.14-.13.2-.33s.08-.38.08-.53a1.93 1.93 0 0 0-.11-.53c-.06-.16-.15-.23-.26-.21" fill={color1} />
                <path d="M2.59 19.22a3.44 3.44 0 0 1 .54-1.92c.1-.15.2-.26.29-.33l.03-.05.11-.04 8.46-1.91.15 2.85-6.91 1.56-.03.06.05.98 6.45-1.45c.11-.03.24.02.39.15.14.13.27.29.4.49.12.2.22.41.31.64.09.23.14.42.15.59l.14 2.82c0 .17-.02.38-.09.63-.07.25-.15.5-.24.73-.1.24-.19.44-.3.6-.1.16-.19.25-.26.27L4.22 27.7c-.13.03-.26.01-.4-.05s-.27-.17-.39-.32a2.18 2.18 0 0 1-.31-.57c-.08-.23-.14-.5-.16-.81l-.35-6.73Zm7.71 2.81-4.9 1.1.05.78c0 .18.05.36.13.52.08.16.16.23.26.21l4.56-1.03c.08-.02.14-.13.2-.33s.08-.38.08-.53a1.93 1.93 0 0 0-.11-.53c-.06-.16-.15-.23-.26-.21Z" fill="none" stroke={color2} strokeWidth=".29" />
                <path d="m20.05 13.17.15 2.85-6.52 1.47.04.75c.02.15.04.24.06.27l5.89-1.33c.1.02.21.08.33.19.12.12.24.26.35.45a3.44 3.44 0 0 1 .45 1.28l.13 2.51c.01.24 0 .51-.06.79s-.13.55-.23.8-.21.46-.34.64c-.13.18-.25.28-.38.31l-8.38 1.89-.15-2.79 6.89-1.55c.05-.11.07-.27.06-.49l-.03-.61-.04-.12-.05-.11-.02-.02-5.75 1.3c-.11.03-.23 0-.36-.07-.13-.07-.25-.2-.36-.37a2.63 2.63 0 0 1-.31-.65 4.33 4.33 0 0 1-.2-.89l-.23-4.44 9.06-2.04Z" fill={color1} />
                <path d="m20.05 13.17.15 2.85-6.52 1.47.04.75c.02.15.04.24.06.27l5.89-1.33c.1.02.21.08.33.19.12.12.24.26.35.45a3.44 3.44 0 0 1 .45 1.28l.13 2.51c.01.24 0 .51-.06.79s-.13.55-.23.8-.21.46-.34.64c-.13.18-.25.28-.38.31l-8.38 1.89-.15-2.79 6.89-1.55c.05-.11.07-.27.06-.49l-.03-.61-.04-.12-.05-.11-.02-.02-5.75 1.3c-.11.03-.23 0-.36-.07-.13-.07-.25-.2-.36-.37a2.63 2.63 0 0 1-.31-.65 4.33 4.33 0 0 1-.2-.89l-.23-4.44 9.06-2.04Z" fill="none" stroke={color2} strokeMiterlimit="10" strokeWidth=".29" />
                <path d="M29.7 20.78a3.4 3.4 0 0 1-.26.7c-.11.2-.23.37-.36.49a.96.96 0 0 1-.37.23l-8.02 1.81c-.06.02-.15-.03-.27-.15a3.91 3.91 0 0 1-.68-1.07 1.57 1.57 0 0 1-.14-.58l-.35-6.79c-.01-.15.01-.35.07-.6a4.75 4.75 0 0 1 .57-1.37c.13-.19.25-.3.39-.33l5.77-1.3.13 2.54-4.26.96s-.05.06-.08.16l-.08.33-.06.34-.02.19.2 3.88c0 .17.05.34.13.51.08.17.15.25.19.24l4.56-1.03s.07-.05.12-.13a1.7 1.7 0 0 0 .22-.57l.04-.21-.37-7.38 1.41-.32c.07-.02.17 0 .3.07.13.07.26.17.4.32a2.3 2.3 0 0 1 .55 1.45l.35 6.74c.02.32 0 .6-.07.86" fill={color1} />
                <path d="M29.7 20.78a3.4 3.4 0 0 1-.26.7c-.11.2-.23.37-.36.49a.96.96 0 0 1-.37.23l-8.02 1.81c-.06.02-.15-.03-.27-.15a3.91 3.91 0 0 1-.68-1.07 1.57 1.57 0 0 1-.14-.58l-.35-6.79c-.01-.15.01-.35.07-.6a4.75 4.75 0 0 1 .57-1.37c.13-.19.25-.3.39-.33l5.77-1.3.13 2.54-4.26.96s-.05.06-.08.16l-.08.33-.06.34-.02.19.2 3.88c0 .17.05.34.13.51.08.17.15.25.19.24l4.56-1.03s.07-.05.12-.13a1.7 1.7 0 0 0 .22-.57l.04-.21-.37-7.38 1.41-.32c.07-.02.17 0 .3.07.13.07.26.17.4.32a2.3 2.3 0 0 1 .55 1.45l.35 6.74c.02.32 0 .6-.07.86Z" fill="none" stroke={color2} strokeMiterlimit="10" strokeWidth=".29" />
                <path d="m30.29 15.78 4.78-1.08c.06-.01.14 0 .24.04.1.04.19.1.28.18a1.02 1.02 0 0 1 .36.72l.06 1.12a2.79 2.79 0 0 1-.19.79l.09.22.08.23.06.19.03.1.06 1.11c0 .16-.02.31-.07.45a1.66 1.66 0 0 1-.46.7.67.67 0 0 1-.23.14l-4.78 1.08-.3-5.99Zm4.05.59-2.57.58.15 3.04 2.57-.58c.08-.02.15-.07.19-.14s.06-.19.05-.34v-.22a.33.33 0 0 0-.03-.15l-2.44.55-.07-1.48 2.43-.55.01-.15v-.21c0-.15-.04-.26-.09-.31a.2.2 0 0 0-.2-.05" fill={color1} />
                <path d="m30.29 15.78 4.78-1.08c.06-.01.14 0 .24.04.1.04.19.1.28.18a1.02 1.02 0 0 1 .36.72l.06 1.12a2.79 2.79 0 0 1-.19.79l.09.22.08.23.06.19.03.1.06 1.11c0 .16-.02.31-.07.45a1.66 1.66 0 0 1-.46.7.67.67 0 0 1-.23.14l-4.78 1.08-.3-5.99Zm4.05.59-2.57.58.15 3.04 2.57-.58c.08-.02.15-.07.19-.14s.06-.19.05-.34v-.22a.33.33 0 0 0-.03-.15l-2.44.55-.07-1.48 2.43-.55.01-.15v-.21c0-.15-.04-.26-.09-.31a.2.2 0 0 0-.2-.05Z" fill="none" stroke={color2} strokeMiterlimit="10" strokeWidth=".29" />
            </g>
        </svg>
    );
});

WHEELS650b.displayName = 'WHEELS650b';
WHEELS650b.defaultProps = {
    className: '',
    color1: '#fff',
    color2: '#000',
    height: 38.27,
    strokeWidth: 3.19,
    testId: 'WHEELS650b',
    width: 38.27
};