/* eslint-disable max-len */
import React from 'react';

interface HCTProps {
    /**
     * A string representing the CSS class to be applied to the HCTIcon element to override its default styling.
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     */
    color1?: string;
    /**
     * A number or string representing the height of the HCTIcon element.
     */
    height?: number | string;
    /**
     * A number representing the stroke width of the HCTIcon element.
     */
    strokeWidth?: number;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     */
    testId?: string;
    /**
     * A number or string representing the height of the HCTIcon element.
     */
    width?: number | string;
}

/**
 * The `HCT` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `HCTProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the HCTIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the HCTIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the HCTIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const HCTComponent = <HCT className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const HCT = React.forwardRef<SVGSVGElement, HCTProps>((props, ref) => {
    const {className, color1, height, strokeWidth, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 38.27 38.27"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <defs>
                <clipPath id="a">
                    <path d="M7.86 7.85a15.96 15.96 0 1 0 .02-.01" fill="none" strokeWidth="0" />
                </clipPath>
            </defs>
            <path d="M19.13 36.67c9.67 0 17.54-7.87 17.54-17.54S28.8 1.59 19.13 1.59 1.59 9.46 1.59 19.13s7.87 17.54 17.54 17.54" fill={color1} />
            <g clipPath="url(#a)">
                <path d="M35.08 19.13a15.94 15.94 0 1 1-31.86 0 15.94 15.94 0 0 1 31.85 0" />
                <path d="M19.13 35.92c-9.26 0-16.79-7.53-16.79-16.79S9.88 2.35 19.13 2.35s16.79 7.53 16.79 16.79-7.53 16.79-16.79 16.79" />
                <path d="M19.13 35.87C9.9 35.87 2.4 28.36 2.4 19.14S9.91 2.41 19.13 2.41s16.73 7.51 16.73 16.73-7.51 16.73-16.73 16.73" fill={color1} />
                <path d="M19.13 2.69c9.07 0 16.45 7.38 16.45 16.45S28.2 35.59 19.13 35.59 2.68 28.21 2.68 19.14 10.06 2.69 19.13 2.69m0-.56a17 17 0 1 0-.01 34.03 17 17 0 0 0 .01-34.04" />
                <path d="M19.13 35.92c-9.26 0-16.79-7.53-16.79-16.79S9.88 2.35 19.13 2.35s16.79 7.53 16.79 16.79-7.53 16.79-16.79 16.79" />
                <path d="M19.13 3.19c8.79 0 15.94 7.15 15.94 15.94s-7.15 15.94-15.94 15.94S3.19 27.92 3.19 19.13 10.34 3.19 19.13 3.19m0-1.69a17.63 17.63 0 1 0 0 35.26 17.63 17.63 0 0 0 0-35.26" fill={color1} />
                <path d="M7.84 17.57h1.31v1.81H6.56v3.76H4.55v-9.08h2.01v3.52l1.28-.01zm13.09-.73c0-.08 0-.17-.02-.26a1.6 1.6 0 0 0-.08-.38c-.03-.08-.06-.09-.07-.1-.04-.03-.16-.09-.5-.14a9.31 9.31 0 0 0-1.49-.1h-1.82c-.43 0-.86.03-1.3.07-.85.07-1.02.23-1.03.23-.02.02-.17.2-.26 1-.02.15-.03.32-.03.48l-.01.49v.02l-.01.5v.51c0 .8.07 1.41.22 1.83.08.23.53.37 1.24.37l.96.02h.95l1.81-.04c.74 0 1.12-.11 1.3-.19.07-.03.2-.25.2-.84v-.84h2.04v.51c0 1.11-.18 1.89-.54 2.38-.4.54-1.25.81-2.67.83l-2.33.03-.17.01h-.51c-.31 0-.62 0-.93-.02-.31-.01-.62-.03-.94-.07a2.86 2.86 0 0 1-1.92-.83 3.06 3.06 0 0 1-.71-1.94l-.02-.39-.01-.6H9.69v-1.81h2.64s.05-.97.1-1.22c.18-.9.55-1.51 1.11-1.81a4.42 4.42 0 0 1 1.87-.48l.76-.05.75-.02h1.06l.25-.01h.63c.36 0 .73 0 1.09.03.37.02.75.07 1.13.15 1.07.21 1.46.81 1.6 1.28.15.53.24 1.1.27 1.69l.02.53-2.02-.02v-.8m2.54-.95v-1.83h10.24v1.83H29.6v7.25h-2.01v-7.25h-4.1z" fill={color1} />
            </g>
            <path d="M19.13 36.67c9.67 0 17.54-7.87 17.54-17.54S28.8 1.59 19.13 1.59 1.59 9.46 1.59 19.13s7.87 17.54 17.54 17.54Z" fill="none" stroke={color1} strokeWidth={strokeWidth} />
        </svg>
    );
});

HCT.displayName = 'HCT';
HCT.defaultProps = {
    className: '',
    color1: '#fff',
    height: 38.27,
    strokeWidth: 3.19,
    testId: 'HCT',
    width: 38.27
};