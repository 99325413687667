/* eslint-disable max-len */
import React from 'react';

interface WEIGHT180Props {
    /**
     * A string representing the CSS class to be applied to the WEIGHT180Icon element to override its default styling.
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     */
    color1?: string;
    /**
     * A string representing the color to be applied.
     */
    color2?: string;
    /**
     * A number or string representing the height of the WEIGHT180Icon element.
     */
    height?: number | string;
    /**
     * A number representing the stroke width of the WEIGHT180Icon element.
     */
    strokeWidth?: number;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     */
    testId?: string;
    /**
     * A number or string representing the height of the WEIGHT180Icon element.
     */
    width?: number | string;
}

/**
 * The `WEIGHT180` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `WEIGHT180Props` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the WEIGHT180Icon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.color2    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the WEIGHT180Icon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the WEIGHT180Icon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const WEIGHT180Component = <WEIGHT180 className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const WEIGHT180 = React.forwardRef<SVGSVGElement, WEIGHT180Props>((props, ref) => {
    const {className, color1, color2, height, strokeWidth, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 38.27 38.27"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <defs>
                <clipPath id="a">
                    <path d="M14.71 3.81a15.96 15.96 0 0 0 0 30.64h8.86a15.95 15.95 0 0 0-.01-30.64h-8.85Z" fill="none" strokeWidth="0" />
                </clipPath>
            </defs>
            <path d="M19.13 36.67c9.67 0 17.54-7.87 17.54-17.54S28.8 1.59 19.13 1.59 1.59 9.46 1.59 19.13s7.87 17.54 17.54 17.54" fill={color1} />
            <g clipPath="url(#a)">
                <path d="M19.48 3.81c-8.45 0-15.32 6.87-15.32 15.32s6.87 15.32 15.32 15.32S34.8 27.58 34.8 19.13 27.92 3.81 19.48 3.81" />
                <path d="m26.61 22.19-2.07.46-1.8 2.96-.04-2.54-1.65.37.1 7.03 1.65-.38-.04-2.54 1.89 2.13 2.07-.47-2.62-2.93 2.51-4.09z" fill={color1} />
                <path d="m26.61 22.19-2.07.46-1.8 2.96-.04-2.54-1.65.37.1 7.03 1.65-.38-.04-2.54 1.89 2.13 2.07-.47-2.62-2.93 2.51-4.09z" fill="none" stroke={color2} strokeMiterlimit="10" strokeWidth=".28" />
                <path d="m32.34 27.94-.06-3.77-2.58.57v1.46l1.1-.25v.99l-2.36.55-.1-.01c-.06-.01-.11-.03-.18-.07s-.12-.08-.17-.15-.07-.16-.07-.26l-.05-3.1c.03-.16.08-.31.15-.44.07-.13.13-.25.2-.34l4.03-.92v-1.27l-5.18 1.17c-.09.02-.18.09-.27.23a2.92 2.92 0 0 0-.49 1.7l.05 3.51c0 .28.05.52.12.72.07.2.16.36.27.49.1.13.22.22.34.27.12.05.23.07.32.05l4.93-1.12Z" fill={color1} />
                <path d="m32.34 27.94-.06-3.77-2.58.57v1.46l1.1-.25v.99l-2.36.55-.1-.01c-.06-.01-.11-.03-.18-.07s-.12-.08-.17-.15-.07-.16-.07-.26l-.05-3.1c.03-.16.08-.31.15-.44.07-.13.13-.25.2-.34l4.03-.92v-1.27l-5.18 1.17c-.09.02-.18.09-.27.23a2.92 2.92 0 0 0-.49 1.7l.05 3.51c0 .28.05.52.12.72.07.2.16.36.27.49.1.13.22.22.34.27.12.05.23.07.32.05l4.93-1.12Z" fill="none" stroke={color2} strokeMiterlimit="10" strokeWidth=".28" />
                <path d="m10.45 14.48-4.58 1.01-.56 2.57 1.9-.42-2.07 9.7 2.65-.58 2.25-10.38.41-1.9z" fill={color1} />
                <path d="m10.45 14.48-4.58 1.01-.56 2.57 1.9-.42-2.07 9.7 2.65-.58 2.25-10.38.41-1.9z" fill="none" stroke={color2} strokeMiterlimit="10" strokeWidth=".28" />
                <path d="M21.49 13.19c-.06-.22-.16-.4-.28-.56a1.27 1.27 0 0 0-.42-.33c-.16-.06-.3-.08-.42-.06l-8.6 1.9c-.12.03-.28.11-.48.26a5.2 5.2 0 0 0-1.18 1.3c-.17.27-.28.54-.34.81l-.54 2.5c-.02.07-.03.17-.02.31 0 .14.02.29.04.44l.07.44.06.29-.22.39-.28.6-.25.57-.11.28-.54 2.44c-.06.27-.05.52.02.73.07.22.16.4.29.54.12.15.26.25.42.32.15.07.29.09.41.06l8.6-1.9c.13-.03.3-.12.5-.26A4.93 4.93 0 0 0 19.39 23c.17-.27.28-.54.34-.81l.54-2.44v-.27l-.04-.5c-.02-.18-.03-.35-.06-.51a1.11 1.11 0 0 0-.08-.33l.22-.35.28-.51.26-.51c.08-.16.12-.27.13-.33l.54-2.5c.06-.27.05-.52 0-.74m-3.97 7.85c-.11.51-.26.88-.43 1.1-.17.23-.38.37-.61.42l-5.17 1.14c-.21.05-.35-.02-.44-.19s-.07-.51.04-1.02l.17-.66c.07-.27.15-.48.24-.64l6.34-1.4c.06.13.06.32 0 .59l-.14.65m1.05-4.84-.17.68c-.07.25-.16.44-.28.59l-6.25 1.38c-.06-.11-.06-.28-.03-.52s.08-.46.12-.67c.11-.51.25-.87.41-1.1a.96.96 0 0 1 .55-.41l5.17-1.14c.24-.05.4 0 .49.18.09.17.08.51-.03 1.01" fill={color1} />
                <path d="M21.49 13.19c-.06-.22-.16-.4-.28-.56a1.27 1.27 0 0 0-.42-.33c-.16-.06-.3-.08-.42-.06l-8.6 1.9c-.12.03-.28.11-.48.26a5.2 5.2 0 0 0-1.18 1.3c-.17.27-.28.54-.34.81l-.54 2.5c-.02.07-.03.17-.02.31 0 .14.02.29.04.44l.07.44.06.29-.22.39-.28.6-.25.57-.11.28-.54 2.44c-.06.27-.05.52.02.73.07.22.16.4.29.54.12.15.26.25.42.32.15.07.29.09.41.06l8.6-1.9c.13-.03.3-.12.5-.26A4.93 4.93 0 0 0 19.39 23c.17-.27.28-.54.34-.81l.54-2.44v-.27l-.04-.5c-.02-.18-.03-.35-.06-.51a1.11 1.11 0 0 0-.08-.33l.22-.35.28-.51.26-.51c.08-.16.12-.27.13-.33l.54-2.5c.06-.27.05-.52 0-.74Zm-3.97 7.85c-.11.51-.26.88-.43 1.1-.17.23-.38.37-.61.42l-5.17 1.14c-.21.05-.35-.02-.44-.19s-.07-.51.04-1.02l.17-.66c.07-.27.15-.48.24-.64l6.34-1.4c.06.13.06.32 0 .59l-.14.65Zm1.05-4.84-.17.68c-.07.25-.16.44-.28.59l-6.25 1.38c-.06-.11-.06-.28-.03-.52s.08-.46.12-.67c.11-.51.25-.87.41-1.1a.96.96 0 0 1 .55-.41l5.17-1.14c.24-.05.4 0 .49.18.09.17.08.51-.03 1.02Z" fill="none" stroke={color2} strokeMiterlimit="10" strokeWidth=".28" />
                <path d="m31.27 19.69 1.78-8.19c.07-.34.08-.62.05-.85-.04-.23-.1-.42-.2-.57a.8.8 0 0 0-.31-.32c-.11-.07-.2-.09-.27-.07l-1.51.33-1.84 8.51a6.44 6.44 0 0 1-.73 1.33c-.1.14-.18.22-.24.23l-5.45 1.2s-.09-.02-.14-.1a1.03 1.03 0 0 1-.1-.31 2.31 2.31 0 0 1 .01-.89l1.02-4.71.12-.3.27-.57c.1-.2.19-.39.29-.56.09-.17.16-.26.2-.27l4.91-1.09.49-2.22-6.72 1.48c-.14.03-.29.14-.46.33a7.97 7.97 0 0 0-.91 1.38 3.1 3.1 0 0 0-.23.58l-1.79 8.26c-.03.17-.04.36-.01.58.03.21.08.41.14.6.06.19.14.34.22.45.08.11.16.16.22.15l9.59-2.12c.12-.03.26-.1.42-.23a3.34 3.34 0 0 0 .91-1.18c.13-.27.23-.55.3-.85" fill={color1} />
                <path d="m31.27 19.69 1.78-8.19c.07-.34.08-.62.05-.85-.04-.23-.1-.42-.2-.57a.8.8 0 0 0-.31-.32c-.11-.07-.2-.09-.27-.07l-1.51.33-1.84 8.51a6.44 6.44 0 0 1-.73 1.33c-.1.14-.18.22-.24.23l-5.45 1.2s-.09-.02-.14-.1a1.03 1.03 0 0 1-.1-.31 2.31 2.31 0 0 1 .01-.89l1.02-4.71.12-.3.27-.57c.1-.2.19-.39.29-.56.09-.17.16-.26.2-.27l4.91-1.09.49-2.22-6.72 1.48c-.14.03-.29.14-.46.33a7.97 7.97 0 0 0-.91 1.38 3.1 3.1 0 0 0-.23.58l-1.79 8.26c-.03.17-.04.36-.01.58.03.21.08.41.14.6.06.19.14.34.22.45.08.11.16.16.22.15l9.59-2.12c.12-.03.26-.1.42-.23a3.34 3.34 0 0 0 .91-1.18c.13-.27.23-.55.3-.85Z" fill="none" stroke={color2} strokeMiterlimit="10" strokeWidth=".28" />
            </g>
            <path d="M19.13 36.67c9.67 0 17.54-7.87 17.54-17.54S28.8 1.59 19.13 1.59 1.59 9.46 1.59 19.13s7.87 17.54 17.54 17.54Z" fill="none" stroke={color1} strokeWidth={strokeWidth} />
        </svg>
    );
});

WEIGHT180.displayName = 'WEIGHT180';
WEIGHT180.defaultProps = {
    className: '',
    color1: '#fff',
    color2: '#000',
    height: 38.27,
    strokeWidth: 3.19,
    testId: 'WEIGHT180',
    width: 38.27
};