/* eslint-disable max-len */
import React from 'react';

interface PRESSFitBbProps {
    /**
     * A string representing the CSS class to be applied to the PRESSFitBbIcon element to override its default styling.
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     */
    color1?: string;
    /**
     * A string representing the color to be applied.
     */
    color2?: string;
    /**
     * A number or string representing the height of the PRESSFitBbIcon element.
     */
    height?: number | string;
    /**
     * A number representing the stroke width of the PRESSFitBbIcon element.
     */
    strokeWidth?: number;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     */
    testId?: string;
    /**
     * A number or string representing the height of the PRESSFitBbIcon element.
     */
    width?: number | string;
}

/**
 * The `PRESSFitBb` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `PRESSFitBbProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the PRESSFitBbIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.color2    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the PRESSFitBbIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the PRESSFitBbIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const PRESSFitBbComponent = <PRESSFitBb className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const PRESSFitBb = React.forwardRef<SVGSVGElement, PRESSFitBbProps>((props, ref) => {
    const {className, color1, color2, height, strokeWidth, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 44.74 40.9"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <defs>
                <clipPath id="a">
                    <path d="M12.54 7.24a15.96 15.96 0 0 0 6.59 30.48c4.4 0 8.39-1.78 11.27-4.67l.01-.01A15.94 15.94 0 0 0 12.54 7.26" fill="none" strokeWidth="0" />
                </clipPath>
            </defs>
            <path d="M19.13 39.31c9.67 0 17.54-7.87 17.54-17.54S28.8 4.23 19.13 4.23 1.59 12.1 1.59 21.77s7.87 17.54 17.54 17.54" fill={color1} />
            <g clipPath="url(#a)">
                <path d="M35.08 21.77a15.94 15.94 0 1 1-31.86 0 15.94 15.94 0 0 1 31.85 0" />
                <path d="M19.13 38.55c-9.26 0-16.79-7.53-16.79-16.79S9.88 4.98 19.13 4.98s16.79 7.53 16.79 16.79-7.53 16.79-16.79 16.79" />
                <path d="M37.92 21.51a18.35 18.35 0 1 1-36.7-.02 18.35 18.35 0 0 1 36.7.03" />
                <path d="M37.92 21.51a18.35 18.35 0 1 1-36.7-.02 18.35 18.35 0 0 1 36.7.03Z" fill="none" stroke={color1} strokeMiterlimit="10" strokeWidth="1.69" />
                <path d="M11.58 15.94c3.69-1.63 7.55.68 9.38 2.71-.18.31-.16.22-.34.11-.68-.39-1.22-1.18-2.03-1.69a8 8 0 0 0-4.07-1.47c-.96-.05-1.85.3-2.94.34" fill={color1} fillRule="evenodd" />
                <path d="M27.51 23.51c.07 3.11-.94 4.36-2.37 6.22-1.81 2.35-3.37 4.73-7.35 4.86-1.39.04-3.16-.32-4.41-.9-3.84-1.79-7.19-6.03-7.57-10.74-.35-4.37 1.79-7.69 5.09-9.04.67-.27 1.49-.31 2.26-.56 1.65-.54 4.11-1.59 6.44-.45 1.78-.85 3.12-2.15 4.86-3.05.45-2.67 1.63-4.19 3.17-5.99C29.12 2.1 31-.11 34.18 0c5.85.22 10.01 4.4 10.51 9.72.5 5.39-2.81 7.92-6.78 9.6-.78.33-1.63.7-2.37.79-1.16.15-2.37-.23-3.39 0-1.73.4-2.93 2.67-4.63 3.39" fill={color1} fillRule="evenodd" />
                <path d="M19.6 29.39c-.23-.31.6-.53.68-.9.23.31-.61.52-.68.9" fill={color1} fillRule="evenodd" />
                <path d="M21.79 19.75c2.87 4.13 2.48 9.35-.85 11.67-3.34 2.32-8.37.85-11.23-3.28-2.87-4.13-2.48-9.36.86-11.67 3.34-2.31 8.37-.85 11.23 3.29" fillRule="evenodd" />
                <path d="M21.79 19.75c2.87 4.13 2.48 9.35-.85 11.67-3.34 2.32-8.37.85-11.23-3.28-2.87-4.13-2.48-9.36.86-11.67 3.34-2.31 8.37-.85 11.23 3.29Z" fill="none" stroke={color2} strokeMiterlimit="10" strokeWidth=".56" />
                <path d="M20.2 20.85c2.12 3.05 1.83 6.91-.63 8.62-2.47 1.71-6.18.63-8.3-2.42-2.12-3.05-1.83-6.91.63-8.62 2.47-1.71 6.18-.62 8.3 2.43" fillRule="evenodd" />
                <path d="M20.2 20.85c2.12 3.05 1.83 6.91-.63 8.62-2.47 1.71-6.18.63-8.3-2.42-2.12-3.05-1.83-6.91.63-8.62 2.47-1.71 6.18-.62 8.3 2.43Z" fill="none" stroke={color1} strokeMiterlimit="10" strokeWidth=".56" />
                <path d="M12.59 18.14c-2.71 1.26-3.51 5.93-.85 9.28 2.04 2.57 5.71 3.25 8.16 1.73.73-.45 0-.71 0-.71s-3.56.71-6.05-3.07c-2.49-3.78-1.25-7.24-1.25-7.24" fill={color1} fillRule="evenodd" />
                <path d="M12.59 18.14c-2.71 1.26-3.51 5.93-.85 9.28 2.04 2.57 5.71 3.25 8.16 1.73.73-.45 0-.71 0-.71s-3.56.71-6.05-3.07c-2.49-3.78-1.25-7.24-1.25-7.24Z" fill="none" stroke={color1} strokeMiterlimit="10" strokeWidth=".56" />
            </g>
            <path d="M19.13 39.31c9.67 0 17.54-7.87 17.54-17.54S28.8 4.23 19.13 4.23 1.59 12.1 1.59 21.77s7.87 17.54 17.54 17.54Z" fill="none" stroke={color1} strokeWidth={strokeWidth} />
        </svg>
    );
});

PRESSFitBb.displayName = 'PRESSFitBb';
PRESSFitBb.defaultProps = {
    className: '',
    color1: '#fff',
    color2: '#000',
    height: 40.9,
    strokeWidth: 3.19,
    testId: 'PRESSFitBb',
    width: 44.74
};