/* eslint-disable max-len */
import React from 'react';

interface SUPERLightProps {
    /**
     * A string representing the CSS class to be applied to the SUPERLightIcon element to override its default styling.
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     */
    color1?: string;
    /**
     * A number or string representing the height of the SUPERLightIcon element.
     */
    height?: number | string;
    /**
     * A number representing the stroke width of the SUPERLightIcon element.
     */
    strokeWidth?: number;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     */
    testId?: string;
    /**
     * A number or string representing the height of the SUPERLightIcon element.
     */
    width?: number | string;
}

/**
 * The `SUPERLight` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `SUPERLightProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the SUPERLightIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the SUPERLightIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the SUPERLightIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const SUPERLightComponent = <SUPERLight className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const SUPERLight = React.forwardRef<SVGSVGElement, SUPERLightProps>((props, ref) => {
    const {className, color1, height, strokeWidth, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 38.27 38.27"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <defs>
                <clipPath id="a">
                    <path d="M7.87 7.9h-.01l-.01.03a15.95 15.95 0 1 0 .03-.02" fill="none" strokeWidth="0" />
                </clipPath>
            </defs>
            <path d="M19.13 36.67c9.67 0 17.54-7.87 17.54-17.54S28.8 1.59 19.13 1.59 1.59 9.46 1.59 19.13s7.87 17.54 17.54 17.54Z" fill="none" stroke={color1} strokeWidth={strokeWidth} />
            <path d="M19.13 36.73c9.67 0 17.54-7.87 17.54-17.54S28.8 1.65 19.13 1.65 1.59 9.52 1.59 19.19s7.87 17.54 17.54 17.54" fill={color1} />
            <g clipPath="url(#a)">
                <path d="M35.08 19.19a15.94 15.94 0 1 1-31.86 0 15.94 15.94 0 0 1 31.85 0" />
                <path d="M19.13 35.98c-9.26 0-16.79-7.53-16.79-16.79S9.88 2.4 19.13 2.4s16.79 7.53 16.79 16.79-7.53 16.79-16.79 16.79" />
                <path d="M1.5 19.19a17.63 17.63 0 1 1 35.25 0 17.63 17.63 0 0 1-35.25 0" fill={color1} />
                <path d="M9.46 28.03c.39-.13.59.13.92.12-.35-.3-1.28-.7-1.35-1.55.42-.12.99.26 1.53.23-.28-.41-1.08-.6-1.42-1.21.28-.29.56.18.9.07a4.45 4.45 0 0 1-.58-2.06c.03-.58.14-1.23.32-1.62.2.32.36.73.52 1.14.07-.57-.43-1.43-.6-2.12.35-.75.45-1.87 1.09-2.32.26.84.56 1.6.94 2.19.04-.93-.67-1.75-.7-2.59-.03-1.06 1.29-2.82 1.83-3.65a15.51 15.51 0 0 1 2.66-3.24c.2.32-.17.56-.24.96-.13.8.24 1.85.72 2.65.05-1.96.55-3.7 1.25-5.34a24.74 24.74 0 0 1 9.23-4.78A16.06 16.06 0 0 0 11.2 33.16c.05-.9.17-1.78.3-2.49-.47-1.24-1.56-1.73-2.04-2.64" fillRule="evenodd" />
                <path d="M31.44 8.87a7.8 7.8 0 0 1-1.92 2.47c-1.21 1.02-2.88 2.08-3.94 3.18-.44.46-1.01 1.23-1.38 1.86-.35.6-.71 1.75-1.07 2.01-.47.33-1.12.35-1.63.56-1.18.5-2.15 1.17-3.09 1.82a25.74 25.74 0 0 1 3.92-1.41c.08.37-.2.79-.37 1.13-.16.34-.35.82-.63 1.1-.78.78-2.34.85-3.54 1.59.75.07 1.93-.75 2.63-.59-.22.62-.89.87-1.44 1.31-.63.51-.93 1.17-1.94 1.49.49 0 1.27-.45 1.65-.5-.27 1.1-1.34 1.81-2.93 2.27.7.23 1.61-.6 2.21-.7-.04 1.03-1.02 1.28-1.83 1.82-.77.52-1.48 1.39-2.56 1.89-.4.18-.85.27-1.27.5a8.5 8.5 0 0 0-.17 3.01 16.06 16.06 0 0 0 19.3-24.79" fillRule="evenodd" />
                <path d="M12.73 24.33c-.69 1.96-.8 4-1.24 6.09l.81.16c.42-7.47 3.88-13.48 9.83-18.28a34.35 34.35 0 0 1 6.58-4.46c-.36-.24-.77.16-1.09.31a27.78 27.78 0 0 0-8.41 5.91 26.83 26.83 0 0 0-6.47 10.27" fillRule="evenodd" />
            </g>
        </svg>
    );
});

SUPERLight.displayName = 'SUPERLight';
SUPERLight.defaultProps = {
    className: '',
    color1: '#fff',
    height: 38.27,
    strokeWidth: 3.19,
    testId: 'SUPERLight',
    width: 38.27
};